@tailwind base;
@tailwind components;
@tailwind utilities;
html.dark {
  --text-default: #e5e7eb; /* Light text */
  --text-secondary: #d1d5db; /* Slightly dimmer */
}

html.light {
  --text-default: #111827; /* Dark text */
  --text-secondary: #4b5563; /* Slightly lighter */
}

body {
  color: var(--text-default);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-default);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  @apply bg-gray-100 text-gray-800;
}
/* In your styles.css */
@media (max-width: 640px) {
  .card {
    width: 100%;  /* Make the card full-width on small screens */
    height: auto; /* Adjust height to fit content */
  }
}
.text-overflow {
  overflow: hidden; /* Hides overflow */
  white-space: nowrap; /* Prevents wrapping */
  text-overflow: ellipsis; /* Adds ellipsis */
}

/* Ensure box-sizing is border-box */
* {
  box-sizing: border-box;
}
